import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
const LazyNavbar = React.lazy(() => import("./global/header"));
const LazyNew = React.lazy(() => import("./global/New"));
const LazyFooter = React.lazy(() => import("./global/footer"));
const LazySEO = React.lazy(() => import("./main/SEO"));
const LazyMedia = React.lazy(() => import("./main/Media"));
// const LazyBlogs = React.lazy(() => import("./main/Blogs"));
// const LazyMarketing = React.lazy(() => import("./main/Marketing"));
const LazyContactus = React.lazy(() => import("./global/Contactus"));
const LazyAbout = React.lazy(() => import("./global/About"));
const LazyMarketingnews = React.lazy(() => import("./main/Marketingnews"));
const LazyDashboard = React.lazy(() =>
  import("./admin/adminComponents/Dashboard")
);
const LazyAddAuthor = React.lazy(() =>
  import("./admin/adminComponents/AddAuthor")
);
const LazyAddCategories = React.lazy(() =>
  import("./admin/adminComponents/AddCategories")
);
const LazyTexteditor = React.lazy(() =>
  import("./admin/adminComponents/Texteditor")
);
const LazyArticlesTable = React.lazy(() =>
  import("./admin/adminComponents/ArticlesTable")
);
const LazyAddArticleForm = React.lazy(() =>
  import("./admin/adminComponents/AddArticleForm")
);
const LazyLoginForm = React.lazy(() =>
  import("./admin/adminComponents/LoginForm")
);
const LazySubscriberTable = React.lazy(() =>
  import("./admin/adminComponents/SubscriberTable")
);
const LazyAuthorpage = React.lazy(() => import("./main/AuthorPage"));
const LazyEditArticle = React.lazy(() =>
  import("./admin/adminComponents/EditArticle")
);
const LazyCategorypage = React.lazy(() => import("./main/CategoryPage"));
const LazyNewsletterTable = React.lazy(() =>
  import("./admin/adminComponents/NewsletterTable")
);

function App() {
  return (
    <div className="App">
      <main className="content">
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/nav" element={<LazyNavbar />} />
            <Route path="/" default element={<LazyNew />} />
            <Route path="/foot" element={<LazyFooter />} />
            <Route path="/seo/:slug" element={<LazySEO />} />
            <Route path="/mediablog" element={<LazyMedia />} />
            {/* <Route path="/blog" element={<LazyBlogs />} /> */}
            {/* <Route path="/market" element={<LazyMarketing />} /> */}
            <Route path="/contact" element={<LazyContactus />} />
            <Route path="/about" element={<LazyAbout />} />
            <Route path="/marketing" element={<LazyMarketingnews />} />
            <Route path="/dashboard" element={<LazyDashboard />} />
            <Route path="/addauthor" element={<LazyAddAuthor />} />
            <Route path="/addcategories" element={<LazyAddCategories />} />
            <Route path="/articlestable" element={<LazyArticlesTable />} />
            <Route path="/editor" element={<LazyTexteditor />} />
            <Route path="/addarticle" element={<LazyAddArticleForm />} />
            <Route path="/admin" element={<LazyLoginForm />} />
            <Route path="/subscribe" element={<LazySubscriberTable />} />
            <Route path="/profile/:authorName" element={<LazyAuthorpage />} />
            <Route path="/editarticle/:slug" element={<LazyEditArticle />} />
            <Route path="/category/:category" element={<LazyCategorypage />} />
            <Route path="/newsletter" element={<LazyNewsletterTable />} />
          </Routes>
        </Suspense>
      </main>
    </div>
  );
}

export default App;
