// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;1,400&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
  font-display: swap;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  font-display: swap;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-display: swap;
}

.App-link {
  color: #61dafb;
  
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.more-from {
  font-family: 'Roboto', serif;
  font-style: italic;
  font-display: swap;
}

.worklife {
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  font-display: swap;
}


.content p img {
  width: 100% !important;
  aspect-ratio: 16/9;
  font-display: swap;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;;AAEpB;;AAEA;EACE,cAAc;EACd,oBAAoB;EACpB,kBAAkB;AACpB;;AAEA;EACE;IACE,4CAA4C;EAC9C;AACF;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,cAAc;;AAEhB;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;;AAKA;EACE,4BAA4B;EAC5B,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,iCAAiC;EACjC,iBAAiB;EACjB,kBAAkB;AACpB;;;AAGA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,kBAAkB;AACpB","sourcesContent":[".App {\n  text-align: center;\n  font-display: swap;\n\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n  font-display: swap;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n  }\n}\n\n.App-header {\n  background-color: #282c34;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n  font-display: swap;\n}\n\n.App-link {\n  color: #61dafb;\n  \n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n\n\n@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;1,400&display=swap');\n\n.more-from {\n  font-family: 'Roboto', serif;\n  font-style: italic;\n  font-display: swap;\n}\n\n.worklife {\n  font-family: 'Roboto', sans-serif;\n  font-weight: bold;\n  font-display: swap;\n}\n\n\n.content p img {\n  width: 100% !important;\n  aspect-ratio: 16/9;\n  font-display: swap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
